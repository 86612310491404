<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <base-card>
          <v-card-title class="d-flex justify-space-between mb-3">
            Customers
            <div class="d-flex justify-center align-center">
              <v-text-field
                v-model="search"
                label="Search"
                append-icon="mdi-magnify"
                class="mr-4"
                outlined
                dense
                hide-details
              ></v-text-field>
              <v-btn
                color="primary"
                @click="
                  $router.push({
                    name: 'add-customer',
                  })
                "
              >
                <v-icon>mdi-plus</v-icon>
                Create New
              </v-btn>
            </div>
          </v-card-title>
          <v-data-table
            item-key="id"
            class="data-table elevation-1"
            :headers="headers"
            :page="getCustomersPage"
            :pageCount="getCustomersPagesNumber"
            :items="getCustomers"
            :options.sync="options"
            :server-items-length="getCustomersTotal"
            :loading="getCustomersLoading"
            :items-per-page="25"
            :footer-props="{
              itemsPerPageOptions: [15, 25, -1],
            }"
          >
            <template v-slot:item.manager="{ item }">
              <v-icon v-if="!!item.manager" color="secondary">mdi-check</v-icon>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu
                origin="bottom center"
                :offset-x="true"
                :offset-y="true"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" small v-bind="attrs" v-on="on">
                    Manage
                    <v-icon right class="ml-0">mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list dense class="py-1">
                  <v-list-item
                    @click="
                      $router.push({
                        name: 'edit-customer',
                        params: { id: item.id },
                      })
                    "
                  >
                    <v-list-item-icon class="mr-1">
                      <v-icon dense>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="showRemoveDialog(item)">
                    <v-list-item-icon class="mr-1">
                      <v-icon dense>mdi-trash-can</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="
                      $router.push({
                        name: 'view-customer-course',
                        params: { id: item.id },
                      })
                    "
                  >
                    <v-list-item-icon class="mr-1">
                      <v-icon dense>mdi-book-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Courses</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="
                      $router.push({
                        path: `/app/customer/${item.id}/students`,
                      })
                    "
                  >
                    <v-list-item-icon class="mr-1">
                      <v-icon dense>mdi-briefcase-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Students</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    link
                    @click="
                      $router.push({
                        path: `/app/customer/${item.id}/students/import`,
                      })
                    "
                  >
                    <v-list-item-icon class="mr-1">
                      <v-icon dense>mdi-arrow-up-box</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Import Students</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </base-card>
        <template v-if="selected">
          <v-row justify="center">
            <v-dialog max-width="350" v-model="removeDialog">
              <v-card>
                <v-card-title>Delete Customer</v-card-title>
                <v-card-text>
                  Are you sure you want to delete this customer? This action
                  cannot be undone.
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="removeDialog = false"> Cancel </v-btn>
                  <v-btn color="danger" text @click="remove"> Delete </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { assign, get } from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Customers",
  },
  data() {
    return {
      search: null,
      selected: null,
      removeDialog: false,
      options: {},
      languages: [],
      headers: [
        { text: "Description", value: "description" },
        { text: "Username", value: "username" },
        { text: "Language", value: "language.name" },
        { text: "Manager", value: "manager", align: "center", sortable: false },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
    };
  },
  methods: {
    ...mapActions(["setCustomers", "deleteCustomer", "toggleCustomerDisabled"]),

    paginate() {
      const options = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
      };

      if (this.search) {
        assign(options, { search: this.search });
      }

      this.setCustomers(options);
    },

    showRemoveDialog(customer) {
      this.selected = customer;
      this.removeDialog = true;
    },

    async remove() {
      if (this.selected) {
        const payload = {
          disabled: get(this.selected, "disabled"),
        };

        await this.toggleCustomerDisabled({
          id: get(this.selected, "id"),
          payload,
        });
      }

      this.selected = null;
      this.removeDialog = false;
    },
  },
  computed: {
    ...mapGetters([
      "getCustomers",
      "getCustomersPage",
      "getCustomersTotal",
      "getCustomersPagesNumber",
      "getCustomersLoading",
      "getSignup",
    ]),

    paginationOptions() {
      return this.options && this.search;
    },
  },
  watch: {
    paginationOptions: {
      handler() {
        this.paginate();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped></style>
