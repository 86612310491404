var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between mb-3"},[_vm._v(" Customers "),_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-text-field',{staticClass:"mr-4",attrs:{"label":"Search","append-icon":"mdi-magnify","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({
                  name: 'add-customer',
                })}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Create New ")],1)],1)]),_c('v-data-table',{staticClass:"data-table elevation-1",attrs:{"item-key":"id","headers":_vm.headers,"page":_vm.getCustomersPage,"pageCount":_vm.getCustomersPagesNumber,"items":_vm.getCustomers,"options":_vm.options,"server-items-length":_vm.getCustomersTotal,"loading":_vm.getCustomersLoading,"items-per-page":25,"footer-props":{
            itemsPerPageOptions: [15, 25, -1],
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.manager",fn:function({ item }){return [(!!item.manager)?_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.actions",fn:function({ item }){return [_c('v-menu',{attrs:{"origin":"bottom center","offset-x":true,"offset-y":true,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-btn',attrs,false),on),[_vm._v(" Manage "),_c('v-icon',{staticClass:"ml-0",attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}],null,true)},[_c('v-list',{staticClass:"py-1",attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
                      name: 'edit-customer',
                      params: { id: item.id },
                    })}}},[_c('v-list-item-icon',{staticClass:"mr-1"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-pencil")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edit")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.showRemoveDialog(item)}}},[_c('v-list-item-icon',{staticClass:"mr-1"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-trash-can")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Delete")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
                      name: 'view-customer-course',
                      params: { id: item.id },
                    })}}},[_c('v-list-item-icon',{staticClass:"mr-1"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-book-multiple")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Courses")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
                      path: `/app/customer/${item.id}/students`,
                    })}}},[_c('v-list-item-icon',{staticClass:"mr-1"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-briefcase-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Students")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$router.push({
                      path: `/app/customer/${item.id}/students/import`,
                    })}}},[_c('v-list-item-icon',{staticClass:"mr-1"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-arrow-up-box")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Import Students")])],1)],1)],1)],1)]}}])})],1),(_vm.selected)?[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"350"},model:{value:(_vm.removeDialog),callback:function ($$v) {_vm.removeDialog=$$v},expression:"removeDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Delete Customer")]),_c('v-card-text',[_vm._v(" Are you sure you want to delete this customer? This action cannot be undone. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.removeDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"danger","text":""},on:{"click":_vm.remove}},[_vm._v(" Delete ")])],1)],1)],1)],1)]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }